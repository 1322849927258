.brand-letter
  stroke-dasharray: 1120.00439453125
  stroke-dashoffset: 1120.00439453125
  animation: sign 6s ease infinite

@keyframes sign
  to
    stroke-dashoffset: 0

@keyframes spin
  to
    transform: rotate(360deg)

.spin
  animation: bounce 1.75s linear infinite

@keyframes bounce
  0%, 100%
    transform: translateY(-25%) scale(1.1)
    animation-timing-function: cubic-bezier(0.8,0,1,1)

  50%
    transform: translateY(25%) scale(0.9)
    animation-timing-function: cubic-bezier(0,0,0.2,1)
