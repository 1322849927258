@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
  font-family: Gotham;
  src: url(./assets/fonts/Gotham-Font/Gotham-Thin.otf);
  font-weight: 100;
}

@font-face {
  font-family: Gotham;
  src: url(./assets/fonts/Gotham-Font/GothamLight.ttf);
  font-weight: 200;
}

@font-face {
  font-family: Gotham;
  src: url(./assets/fonts/Gotham-Font/GothamBook.ttf);
  font-weight: 400;
}

@font-face {
  font-family: Gotham;
  src: url(./assets/fonts/Gotham-Font/GothamMedium.ttf);
  font-weight: 500;
}

@font-face {
  font-family: Gotham;
  src: url(./assets/fonts/Gotham-Font/GothamBold.ttf);
  font-weight: 700;
}

@font-face {
  font-family: Gotham;
  src: url(./assets/fonts/Gotham-Font/Gotham-Black.otf);
  font-weight: 800;
}

* {
  font-family: Gotham, sans-serif;
  color: #212529;
}

.codingformationpic {
  position: relative;
  overflow: hidden;
  height: 100%;
  border-top-left-radius: inherit;
  border-bottom-left-radius: inherit;
}

.codingformationpic img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease-in-out;
}

.codingformationpic img:hover {
  transform: scale(1.05);
  /* Smooth zoom effect on hover */
}

.flipped-svg {
  transform: scaleX(-1);
  /* Flip the SVG horizontally */
  transition: fill 0.3s ease-in-out;
  /* Smooth transition for color changes */
}

.flipped-svg:hover {
  fill: #e0e0e0;
  /* Change color on hover for a smoother effect */
}

.codingformationpic1 svg {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
}

.bg-image {
  background-image: url("./assets/images/gallery/image.png");
  /* background-position: center; */
}
.bg-image-coding {
  background-image: url("./assets/images/gallery/coding.jpg");
  background-position: bottom;
}
.bg-image-media {
  background-image: url("./assets/images/gallery/media.jpg");
}



::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 8px;
  background: #212529;
}

/* To Reset Tailwind Classes in a div and it's children */
.reset-tw,
.reset-tw * {
  all: revert;
}

input[type="checkbox"] {
  width: 15px;
  height: 15px;
  accent-color: #fee819;
}

input[type="radio"] {
  accent-color: black;
}

.projects {
  transition: 0.5s ease;
}

.preview {
  position: absolute;
  top: 120vh;
  left: 0;
  transition: 0.5s ease;

}

.projects:hover>.preview {
  top: 0;
  transition: 0.5s ease;
}

@media (max-width: 640px) {
  .flex-\[calc\(calc\(100\%-calc\(2\*3\.5rem\)\)\/3\)\] {
    flex: 1 0 100%;
  }
}